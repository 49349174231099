<template>
	<section
		data-component="sidebar-list"
	>
		<h1
			data-element="heading"
		>
			{{ heading }}
		</h1>
		<div data-element="list-items">
			<router-link
				data-element="list-item"
				v-for="(listItem, key) in listItems"
				:key="key"
				:to="listItem.route"
			>
				<icon
					:icon="listItemIcon"
				/>
				<span>
					{{ listItem.text }}
				</span>
			</router-link>
		</div>
		<router-link
			v-if="getShowMoreLink"
			data-element="more-link"
			:to="moreLinkRoute"
		>
			<span>
				{{ moreLinkText }}
			</span>
			<icon
				icon="chevron-left"
				colour="blue"
			/>
		</router-link>
	</section>
</template>

<script>

	import Icon from '@/components/ui/Icon';

	export default {
		components: {
			Icon
		},
		props: {
			heading: {
				type: String,
				default: ''
			},
			listItems: {
				type: Array,
				default: () => ([])
			},
			listItemIcon: {
				type: String,
				default: 'book'
			},
			moreLinkText: {
				type: String,
				default: ''
			},
			moreLinkRoute: {
				type: String,
				default: ''
			}
		},
		data: () => ({}),
		computed: {
			getShowMoreLink () {
				if (!this.moreLinkText?.length ||
						!this.moreLinkRoute?.length) {
					return false;
				}
				return true;
			}
		},
		methods: {
			getArticleRoute (article) {
				if (!this.getCategoryRoute ||
						!article?.slug) {
					return false;
				}
				const articleSlug = article.slug;
				return `${this.getCategoryRoute}/${articleSlug}`;
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='sidebar-list'] {
		background-color:$c-brand-grey-lighter-6;
		padding:rem(24) rem(18) rem(26);
		border-radius:8px;
		[data-element='heading'] {
			margin-bottom:rem(16);
			font-size:rem(24);
			font-weight:700;
			color:$c-brand-blue-lighter-1;
		}
		[data-element='list-items'] {
			margin-bottom:rem(16);
			&:last-child {
				margin-bottom:0;
			}
			[data-element='list-item'] {
				display:flex;
				align-items:center;
				margin-bottom:rem(12);
				&:last-child {
					margin-bottom:0;
				}
				[data-component='icon'] {
					flex-shrink:0;
					width:rem(24);
					height:auto;
					margin-right:rem(10);
				}
				span {
					font-size:rem(20);
					font-weight:700;
				}
			}
		}
		[data-element='more-link'] {
			display:flex;
			align-items:center;
			span {
				font-size:rem(16);
				font-weight:700;
				color:$c-brand-blue;
			}
			[data-component='icon'] {
				flex-shrink:0;
				width:rem(18);
				height:auto;
				transform:rotate(180deg);
			}
		}
	}

</style>
